.App {
  position: relative;
}
.downloads-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloads-buttons > button {
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;

  font-weight: 600;
  background: none;
  color: white;
  border: none;

  transition: 300ms;
  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;

  border-bottom: 1px rgb(136, 112, 233, 0) solid;
}
.downloads-buttons > button:hover {
  opacity: 1;
}

.downloads-buttons-active {
  background: linear-gradient(
    180deg,
    rgba(117, 81, 255, 0) 0%,
    rgba(117, 81, 255, 0.5) 100%
  ) !important;
  opacity: 1 !important;
  border-bottom: 1px rgb(136, 112, 233) solid !important;
}

.donwload-btn {
  margin-top: 20px;
  padding: 20px 40px;
  min-width: 300px;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid white;
  background: none;
  color: white;
  font-size: 24px;

  font-weight: 600;
  border-radius: 8px;
  opacity: 50%;
  transition: 300ms;
}

.donwload-btn-hover:hover{
  background: rgb(136, 112, 233);
}

.header-flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-width {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  padding-top: 50px;
}
