.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  height: 100px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 9999;

  backdrop-filter: blur(5px);

  transition: 400ms;
  .links_head > * {
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    transition: 300ms;
  }
  .links_head > *:hover {
    background: rgb(55, 55, 55);
  }
  p {
    margin-left: 40px;
    font-weight: 700;
    font-size: 30px;
  }
  li {
    list-style-type: none;

    display: flex;
    align-items: center;
    justify-content: space-around;

    grid-gap: 5px;

    height: 50px;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-gap: 30px;

    align-items: center;
    margin-right: 40px;
  }
  a {
    color: #fff;
    text-decoration: none;

    font-weight: 700;
  }
  .connect_wallet_btn {
    background: none;
    border-radius: 25px;
    color: #fff;
    font-size: 20px;

    padding: 10px 20px;

    transition: 500ms;

    border: 1px #fff solid;
  }
  .connect_wallet_btn:hover {
    border-color: rgb(181, 0, 244);
  }
  li:hover .dropdown {
    display: flex;
  }
  .dropdown {
    position: absolute;
    top: 70px;
    flex-direction: column;
    gap: 10px;

    padding: 20px 50px;
    border-radius: 15px;
    border: rgba(217, 217, 217, 0.4) 1px solid;
    background: black;
    display: none;
  }
  .dropdown > a {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .dropdown > a > img {
    width: 50px;
    height: 50px;
  }
  .dropdown > a > div > p:first-child {
    font-size: 20px;
    color: #fff;
  }
  .dropdown > a > div > p {
    font-size: 14px;
    margin: 0;
    color: #807474;

    font-weight: 500;
  }
}
.footer {
  width: 100%;
  background: rgba(0, 0, 0, 0.01);
  margin-top: 50px;

  backdrop-filter: blur(5px);
  border-top: rgba(181, 0, 244, 1) 2px solid;
  border-image: linear-gradient(
    to left,
    rgba(181, 0, 244, 0.6),
    rgba(0, 0, 0, 0)
  );
  border-image-slice: 1;
  height: fit-content;
  display: flex;
  align-items: start;
  justify-content: space-around;

  border-radius: 10px;
  color: #fff;
  box-sizing: border-box;
  padding: 20px;
  flex-wrap: wrap;
  div {
    width: 20%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    a {
      width: 100%;
      color: #fff;

      transition: 200ms;
    }
    a:hover {
      color: rgb(23, 80, 193);
    }
  }
  p {
    color: #fff;
    font-size: 40px;
    font-weight: 500;

    display: flex;
    align-items: center;
    gap: 5px;
    width: 20%;
    min-width: 200px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
}
.header_mob {
  display: none;
}
@media (max-width: 700px) {
  .header_mob {
    display: flex;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: rgba(0, 0, 0, 0.8);
    color: #fff;

    z-index: 9999;

    backdrop-filter: blur(5px);

    transition: 400ms;
    width: 100%;

    p:first-child {
      margin-left: 40px;
      font-weight: 700;
      font-size: 30px;
    }
    .open_burger {
      position: absolute;
      right: 10px;
      border: none;
      background: none;
      height: 40px;
      width: 40px;

      p {
        margin: 10px 0;
        height: 2px;
        width: 100%;
        background: #fff;

        transition: 300ms;

        .active {
          filter: rotate(90deg);
        }
      }
    }
  }
  .header {
    display: none;
  }
}
.burger {
  width: 80%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  right: 0;
  top: 0;

  z-index: 99999;

  transition: 500ms;
  right: -100%;

  padding-top: 50px;
  gap: 20px;

  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    margin-top: 50px;
  }
  .close {
    position: absolute;
    top: 25px;
    right: 10px;
    background: none;
    border: none;
    font-size: 40px;
    color: #fff;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    gap: 10px;
    text-align: center;
    img {
      width: 50px;
    }
  }
}
.swiper-slide {
  box-shadow: none !important;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
